

.app {
  max-width: 1280px;
  margin: 0 auto;
}


@media only screen and (max-width: 650px) {
  .navbar {
    display: block;
  }

  .navbar ul {
    margin: 0px;
    justify-content: center;
  }

  .navbar ul a:nth-child(2) {
    margin-right: 0px;
  }

  .navbar .search-wrapper {
    margin-top: 20px;
  }

  .dialog{
    max-width: calc(100vw - 100px);
    min-width: 300px;
  }
}


@media only screen and (max-width: 300px) {
  .navbar ul {
      flex-flow: column;
  }

  .navbar ul a {
    margin-top: 10px;
    margin-right: 0px;

  }

}