
.search-wrapper {
  max-width: 100%;
  padding: 0 1.5em;
  background: white;
  border: 1px solid lightgray;
  border-radius: 1.5em;
  overflow: hidden;
}

.search-wrapper:hover,
.search-wrapper.focus {
  box-shadow: 0 1px 3px 0 rgba(32, 33, 36, 0.28);
}

.search {
  display: flex;
  height: 2em;
  align-items: center;
}
.search input {
  flex: 1;
  width: 100%;
  border: 0;
}

:focus {
  outline: none;
}

.search-icon {
  font-size: 1em;
}

.red-xmark {
  color: #ff0000;
  margin-left: 8px;
  font-size: 13px;
}
