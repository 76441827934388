
.navbar {
  width: 100%;
  display: flex;
  justify-content: left;
  padding: 18px 55px;
  box-shadow: 0px 1px 10px -2px rgb(0 0 0 / 50%);
  margin-bottom: 20px;
  box-sizing: border-box;
}

.navbar ul {
  margin-right: 25px;
  display: flex;
  align-items: center;
}

.navbar ul a {
  margin-right: 40px;
  list-style: none;
  text-decoration: none;
  font-size: 20px;
  text-transform: uppercase;
  color: black;
  height: 22px;
  box-sizing: border-box;
  /* background: red; */
  /* border-bottom: 0px solid blue; */

  transition: color 0.5s;
}

.navbar-link {

}

.navbar ul .navbar-link-active {
  color: rgb(252, 49, 49);

}

.navbar ul a:hover {
  color: rgb(252, 49, 49);
  /* border-bottom: 2px solid blue; */
}
