.pagination {
  padding: 70px;
  display: flex;
  justify-content: center;
}

.pagination-btn {
  font-size: 25px;
  margin-right: 3px;
  

}
